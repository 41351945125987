import { exit } from 'process';

export enum Stages {
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

type Env_t = {
  API_URL: string;
  WEB_APP_URL: string;
  MOBILE_APP_URL: string;
};

type Envs_t = {
  [Stages.DEV]: Env_t;
  [Stages.STAGING]: Env_t;
  [Stages.PROD]: Env_t;
};

const envs: Envs_t = {
  [Stages.DEV]: {
    API_URL: process.env.REACT_APP_API_URL_DEVELOPMENT as string,
    WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL_DEVELOPMENT as string,
    MOBILE_APP_URL: process.env.REACT_APP_MOBILE_APP_URL_DEVELOPMENT as string,
  },
  [Stages.STAGING]: {
    API_URL: process.env.REACT_APP_API_URL_STAGING as string,
    WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL_STAGING as string,
    MOBILE_APP_URL: process.env.REACT_APP_MOBILE_APP_URL_STAGING as string,
  },
  [Stages.PROD]: {
    API_URL: process.env.REACT_APP_API_URL_PRODUCTION as string,
    WEB_APP_URL: process.env.REACT_APP_WEB_APP_URL_PRODUCTION as string,
    MOBILE_APP_URL: process.env.REACT_APP_MOBILE_APP_URL_PRODUCTION as string,
  },
};

export const STAGE: Stages = process.env.REACT_APP_STAGE as Stages;
const allowEnvs: Stages[] = [Stages.DEV, Stages.STAGING, Stages.PROD];
if (!allowEnvs.includes(STAGE)) {
  exit(1);
}

export const API_URL: string = envs[STAGE].API_URL;
export const WEB_APP_URL: string = envs[STAGE].WEB_APP_URL;
export const MOBILE_APP_URL: string = envs[STAGE].MOBILE_APP_URL;

// Functions
export const isDevEnv = () => STAGE === Stages.DEV;
export const isStagingEnv = () => STAGE === Stages.STAGING;
export const isProdEnv = () => STAGE === Stages.PROD;
