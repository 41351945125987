import { Box, Link, Stack, useMediaQuery, useTheme } from '@mui/material';
import Logo from 'components/brands/logo';
import { motion } from 'framer-motion';

const CustomBox = motion(Box);

const variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const links: { name: string; href: string }[] = [
  { name: 'Home', href: '/' },
  { name: 'À propos', href: '/' },
  { name: 'Contact', href: '/' },
];
export default function Header() {
  const theme = useTheme();
  const upXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <CustomBox
      variants={variants}
      initial="hidden"
      animate="visible"
      display="flex"
      justifyContent={{ xs: 'center', sm: 'space-between' }}
      alignItems="center"
      py={4}
      px={5}
      position="absolute"
      top={0}
      width="100%"
    >
      <Logo widthPx={upXl ? 160 : 120} />
      <Box display={{ xs: 'none', sm: 'flex' }} alignItems="center">
        <Stack gap={3} direction="row" display={{ xs: 'none', md: 'flex' }}>
          {links.map((link, key) => (
            <Link
              variant="body2"
              key={key}
              href={link.href}
              color="grey.300"
              underline="hover"
            >
              {link.name}
            </Link>
          ))}
        </Stack>
        {/* <Stack gap={2} direction="row" ml={8}>
          <Button size="small" href="/auth/login">
            Se connecter
          </Button>
          <Button size="small" variant="contained" href="/auth/signup">
            S'inscrire gratuitement
          </Button>
        </Stack> */}
      </Box>
    </CustomBox>
  );
}
