import { Suspense, lazy } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';
// import AuthentificationLayout from 'layouts/authentification';
// import Userlayout from 'layouts/user';
import HomeLayout from 'layouts/home';

export const HomePage = lazy(() => import('../pages/home'));
export const TermsPage = lazy(() => import('../pages/terms'));
export const ValidEmailPage = lazy(() => import('../pages/valid-email'));
export const MobileRedirect = lazy(() => import('../pages/mobile-redirect'));
export const MobilePage = lazy(() => import('../pages/mobile'));
// export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '*',
      element: (
        <HomeLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </HomeLayout>
      ),
      children: [
        {
          element: <HomePage />,
          index: true,
        },
        {
          element: <TermsPage />,
          path: 'terms/*',
        },
      ],
    },
    {
      path: 'valid-email/:token',
      element: <ValidEmailPage />,
    },
    {
      path: 'mobile-redirect',
      element: <MobileRedirect />,
    },

    // For mobile redirection
    {
      path: 'mobile/:pathname',
      element: <MobilePage />,
    },
  ]);

  return routes;
}
