import { Chip, useTheme } from '@mui/material';
import { transparentize } from 'polished';

export default function StagingLabel() {
  const theme = useTheme();

  return (
    <Chip
      label={'Staging environment'}
      sx={{
        fontWeight: 600,
        bgcolor: transparentize(0.85, theme.palette.primary.main),
        color: theme.palette.primary.main,
        position: 'fixed',
        bottom: 20,
        left: 20,
        zIndex: 1000000,
      }}
    />
  );
}
