import Asset from 'components/assets/asset';

// ----------------------------------------------------------------------

type Props_Logo = {
  short?: boolean;
  widthPx: number;
  clickable?: boolean;
  style?: React.CSSProperties;
};

const Logo: React.FC<Props_Logo> = ({
  short,
  widthPx,
  clickable = true,
  style,
}: Props_Logo) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    !clickable && e.preventDefault();
  };

  return (
    <a
      href="/"
      style={{ cursor: clickable ? 'pointer' : 'default', ...style }}
      onClick={handleClick}
    >
      {short ? (
        <Asset pathname="/logo/trsform-short.png" widthPx={widthPx} />
      ) : (
        <Asset pathname="/logo/trsform-2.svg" widthPx={widthPx} />
      )}
    </a>
  );
};

export default Logo;
